import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import { useIndustrialPageHeadingSection } from "../hooks/IndustrialPage/useIndustrialPageHeadingSection"
import { useIndustrialPageMiddleSection } from "../hooks/IndustrialPage/useIndustrialPageMiddleSection"
import { useIndustrialPageBottomSection } from "../hooks/IndustrialPage/useIndustrialPageBottomSection"
import Layout from "../components/Layout/Layout"
import TemplatePageHeaderSection from "../components/TemplatePageHeaderSection/TemplatePageHeaderSection"
import TemplatePageContentSection from "../components/TemplatePageContentSection/TemplatePageContentSection"
import TemplatePageConfigSection from "../components/TemplatePageConfigSection/TemplatePageConfigSection"
import Footer from "../components/Footer/Footer"
import SeoPage from "../components/seo-page"

const IndustrialPage = ({ location }) => {
  const { i18n } = useTranslation()
  const { language } = i18n
  const seoFields = {
    keyword: {
      en: "solar panels",
      ro: "panouri solare",
    },
    slug: {
      en: "Panouri solare pentru domeniul industrial",
      ro: "Panouri solare pentru domeniul industrial",
    },
  }

  function getSeoFields(field) {
    return seoFields[field][language] ?? seoFields[field].ro // return ro fields if current language does not have them
  }
  const {
    title: headingTitle,
    subtitle,
    buttonLink: headingButton,
    headingSectionImage,
  } = useIndustrialPageHeadingSection()
  const {
    middleSectionContent: { ...content },
    middleSectionServices,
  } = useIndustrialPageMiddleSection()
  const {
    title: bottomTitle,
    programText,
    notes,
    backgroundImage,
    buttonLink: bottomButton,
    icon,
  } = useIndustrialPageBottomSection()
  return (
    <Layout>
      <SeoPage keyword={getSeoFields("keyword")} slug={getSeoFields("slug")} />
      <TemplatePageHeaderSection
        title={headingTitle}
        subtitle={subtitle}
        buttonLink={headingButton}
        image={headingSectionImage}
        location={location}
      />
      <TemplatePageContentSection
        content={Object.values(content)}
        services={middleSectionServices}
      />
      <TemplatePageConfigSection
        title={bottomTitle}
        programText={programText}
        notes={notes}
        image={backgroundImage}
        icon={icon}
        buttonLink={bottomButton}
      />
      <Footer />
    </Layout>
  )
}

IndustrialPage.propTypes = {
  location: PropTypes.shape({}).isRequired,
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default IndustrialPage
